$zen-color-grey-01: #4B4B4B;
$zen-color-grey-02: #757575;
$zen-color-grey-03: #B4B4B4;
$zen-color-grey-04: #C4C4C4;
$zen-color-grey-05: #E0E0E0;
$zen-color-grey-07: #F3F3F3;

$zen-color-gold-1: #F8B35D;
$zen-color-green-1: #6DA085;

$zen-sidebar-width: 240px;
$zen-round-slider-width: 300px;
$zen-box-size-base: 160px;
$zen-margin-on-sides: 6px;

// Phase 2
$zen-color-header: #14323D;
$zen-color-header-with-alpha: rgba(20, 50, 61, 0.35);
$zen-color-header-with-alpha-60: #14323D60;
$zen-color-footer: $zen-color-header;
$zen-color-footer-with-alpha: $zen-color-header-with-alpha;
$zen-color-layout-rad-grad-inner: #1F5062;
$zen-color-layout-rad-grad-outer: #112C35;
$zen-color-border-dark: #2B7089; // border_dark
$zen-color-tab-bar-active: #FFA726;
$zen-color-secondary-nav: #15586D; // background-ocean-light-solid
$zen-color-error: #D94A4A;
$zen-color-success: #45AC7B;
$zen-color-start-play : #FFB053; // y-01
$zen-color-accent-light-blue : #18FFFF; // accent - light blue
