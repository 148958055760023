/* You can add global styles to this file, and also import other style files */
//
@use '@angular/material' as mat;
@import "styles/variables";
@import "styles/mixins";


// 3rd party component styles
// https://material.angular.io/guide/theming

@include mat.core();

$zen-primary: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: rgba(black, 0.87),
                600: rgba(black, 0.87),
                700: rgba(black, 0.87),
                800: rgba(black, 0.87),
                900: rgba(black, 0.87),
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: rgba(black, 0.87),
        )
);

$zen-accent: (
        50: #fef6ed,
        100: #fbe9d2,
        200: #f9dbb4,
        300: #f7cc95,
        400: #f5c17f,
        500: #f3b668,
        600: #f1af60,
        700: #efa655,
        800: #ed9e4b,
        900: #ea8e3a,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffe5cf,
        A700: #ffd7b5,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: white,
                400: white,
                500: white,
                600: white,
                700: white,
                800: white,
                900: white,
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: rgba(black, 0.87),
        )
);

$zen-primary-variant: (
        50: #F7FAF8,
        100: #F7FAF8,
        200: #DFE9E3,
        300: #D2E0D8,
        400: #C8D9CF,
        500: #BED2C7,
        600: #B8CDC1,
        700: #AFC7BA,
        800: #A7C1B3,
        900: #99B6A6,
        A100: #FFFFFF,
        A200: #FFFFFF,
        A400: #F1FFF7,
        A700: #D7FFE9,
        contrast: (
                50: rgba(black, 0.87),
                100: rgba(black, 0.87),
                200: rgba(black, 0.87),
                300: rgba(black, 0.87),
                400: rgba(black, 0.87),
                500: rgba(black, 0.87),
                600: rgba(black, 0.87),
                700: rgba(black, 0.87),
                800: rgba(black, 0.87),
                900: rgba(black, 0.87),
                A100: rgba(black, 0.87),
                A200: rgba(black, 0.87),
                A400: rgba(black, 0.87),
                A700: rgba(black, 0.87),
        )
);

$my-primary: mat.define-palette($zen-primary);
$my-accent: mat.define-palette($zen-accent);
$my-variant: mat.define-palette($zen-primary-variant);
$my-warn: mat.define-palette(mat.$red-palette);

$my-typography: mat.define-typography-config(
        $font-family: 'Quicksand, monospace',
        $body-1: mat.define-typography-level(16px, 24px, 500)
);


$my-theme: mat.define-dark-theme((
        color: (
                primary: $my-variant,
                accent: $my-accent,
                warn: $my-warn,
        ),
        typography: $my-typography,
        density: 0,
));

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

html, body {
  user-select: none;
}

body {
  margin: 0;
  background: $zen-color-layout-rad-grad-outer;
}
